import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/parts/Layout";
import { BlogPostList } from "../../components/parts/BlogPostList";
import { snProps } from "../../js/utils";

import * as blogStyles from "../../components/global-styles/blog.module.css";

const BlogPage = ({ data }) => (
  <Layout
    title="Blog"
    description="News, updates, and developments related to Clairnote music notation systems"
    location={{ pathname: "/blog/" }}
    isBlog={true}
    {...snProps}
  >
    <h1 className={`title is-3 ${blogStyles.blogTitle}`}>The Clairnote Blog</h1>
    <BlogPostList edges={data.allMarkdownRemark.edges} />
  </Layout>
);

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { draft: { ne: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            author
            special_byline
            tags
          }
          fields {
            path
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
